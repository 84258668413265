export default function mobileNavToggle() {
  if (document.querySelector('.navbar-burger')) {
    const burger = document.querySelector('.navbar-burger');
    const navMenu = document.querySelector('#navMenu');

    burger.addEventListener('click', (event) => {
      navMenu.classList.toggle('is-active');
    });
  }
}
