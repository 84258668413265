import React from 'react';

export default function Success() {
  const images = require.context('../../img/', true);
  return (
    <React.Fragment>
      <section className="hero is-white is-fullheight has-text-centered animate-fadeUp success">
        <div className="hero-body" style={{ paddingTop: '0' }}>
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-three-fifths">
                <img
                  src={images(`./success.svg`)}
                  style={{ marginBottom: '48px', maxWidth: '300px' }}
                />
                <h1 className="title is-spaced is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                  Message received. Thanks!
                </h1>
                <h2 className="subtitle is-size-4-desktop">
                  I'll be in touch with you shortly.
                </h2>
                <a
                  className="button is-primary is-outlined is-medium is-rounded"
                  style={{ marginTop: '30px', fontWeight: '500' }}
                  href="/"
                >
                  Back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
