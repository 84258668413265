import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Projects from '../data/projects.json';

class Portfolio extends Component {
  render() {
    const images = require.context('../../img/projects', true);
    const projects = Projects.map((project, index) => {
      return (
        <div className="column is-4 my-5" key={index}>
          <Link
            className="project_link project"
            to={`/portfolio/${project.url}`}
          >
            <picture>
              <img
                src={images(`./${project.featuredImage}`)}
                className="project-image"
                alt="Carlos Hernandez - Project"
              />
            </picture>
          </Link>
        </div>
      );
    });

    return (
      <section className="section">
        <div className="container">
          <h1 className="title is-spaced is-size-1-desktop is-size-4-mobile has-text-centered">
            My Recent Work
          </h1>
          <h2 className="subtitle is-size-5-desktop has-text-centered">
            Here are a few web development projects I've worked on recently.
          </h2>
          <div className="columns is-multiline projects">{projects}</div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
