import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../img/carlos-hernandez.png';

import mobileNavToggle from '../mobileNavToggle';

function Nav() {
  useEffect(() => {
    mobileNavToggle();

    return function cleanup() {
      document.querySelector('#navMenu').classList.remove('is-active');
    };
  });
  return (
    <header>
      <nav
        className="navbar is-transparent py-3"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img
                src={Logo}
                alt="Carlos Hernandez | Web Developer"
                height="48"
              />
              {/* Carlos Hernandez */}
            </a>
            <a
              className="navbar-burger"
              role="button"
              aria-label="menu"
              aria-expanded="false"
              data-target="#navMenu"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div className="navbar-menu" id="navMenu">
            <div className="navbar-start"></div>
            <div className="navbar-end">
              <div className="navbar-item">
                <NavLink to="/" activeClassName="active">
                  Portfolio
                </NavLink>
              </div>
              <div className="navbar-item">
                <NavLink to="/about">About</NavLink>
              </div>
              <div className="navbar-item">
                <a
                  href="/contact"
                  className="button is-primary is-outlined is-rounded has-text-primary"
                >
                  Say Hello
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Nav;
