import React from 'react';
import Projects from '../data/projects.json';

class ProjectDetails extends React.Component {
  componentDidMount() {
    let modalImages = document.querySelectorAll('.modal-image');
    let target;

    modalImages.forEach((image, index) => {
      image.addEventListener('click', (event) => {
        //console.log(event.target);
        target = image.getAttribute('data-target');
        document.querySelector(`#${target}`).classList.add('is-active');
        document.documentElement.classList.add('is-clipped');
      });
    });

    document.querySelectorAll('.modal-close').forEach((btn) => {
      btn.addEventListener('click', () => {
        document.documentElement.classList.remove('is-clipped');
        document.querySelector(`#${target}`).classList.remove('is-active');
      });
    });
  }

  render() {
    const { params } = this.props.match;

    const images = require.context('../../img/projects', true);

    const filteredProject = Projects.filter((item) => {
      return item.url === params.project;
    });

    const project = filteredProject.map((item, index) => {
      const test = item.images.map((item, i) => {
        return (
          <div
            className="column is-one-third my-6"
            key={`column${item}${index}`}
          >
            <figure>
              <img
                src={images(`./${item}`)}
                alt="carlos"
                className="modal-image"
                key={index + item}
                data-target={`test${i}`}
              />
            </figure>

            {/* is-active */}
            <div className="modal" id={`test${i}`}>
              <div className="modal-background"></div>
              <div className="modal-content">
                <p className="image has-ratio">
                  <img src={images(`./${item}`)} alt="" />
                </p>
              </div>
              <button
                className="modal-close is-large"
                aria-label="close"
              ></button>
            </div>
          </div>
        );
      });

      return (
        <div className="contents" key={index}>
          <h1 className="title is-1 pt-6">{item.title}</h1>
          <picture>
            <img
              src={images(`./${item.featuredImage}`)}
              alt={item.title}
              className="full-width"
            />
          </picture>

          <section className="section my-6">
            <div className="columns is-multiline">{test}</div>
          </section>
        </div>
      );
    });

    return (
      <section>
        <div className="container">{project}</div>
      </section>
    );
  }
}

export default ProjectDetails;
