import React from 'react';

class About extends React.Component {
  render() {
    return (
      <section>
        <div className="container">
          <h1 className="title is-one pt-6">Carlos Hernandez</h1>
          <p>
            Over the years that I have worked as a developer, one characteristic
            that has been integral to my successes has been my keen ability to
            collaborate with creative and marketing teams. I am seeking an
            opportunity with a stable, successful company with whom I can grow
            and one that will gain value from my many years of experience.
          </p>
        </div>
      </section>
    );
  }
}

export default About;
