import React from 'react';
import Nav from './components/layout/Nav';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Portfolio from './components/pages/Portfolio';
import About from './components/pages/About';
import ProjectDetails from './components/pages/Project_details';
import Contact from './components/pages/Contact';
import Success from './components/pages/Success';

// Components
import DynamicScrollToTop from './components/scrollToTop';

function App() {
  return (
    <div className="App">
      {/* Uses a transparent header that draws on top of the layout's background */}
      <Router>
        {window.location.pathname === '/success' ? '' : <Nav />}
        <main>
          <Switch>
            <Route exact path="/" component={Portfolio} />
            <Route
              exact
              path="/portfolio/:project"
              component={ProjectDetails}
            ></Route>
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/success" component={Success} />
            <DynamicScrollToTop />
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
