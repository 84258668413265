import React from 'react';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { feedback: '', name: '', email: '' };
    this.onChangeTextarea = this.onChangeTextarea.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <section className="section contact my-6">
        <div className="container">
          <form className="test-mailing">
            <div className="field">
              <label className="label">Nombre</label>
              <div className="control">
                <input
                  onChange={this.onChangeName}
                  id="name"
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Escriba su nombre aquí"
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Email</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  onChange={this.onChangeEmail}
                  id="email"
                  className="input"
                  type="email"
                  placeholder="Provea un email válido"
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-envelope"></i>
                </span>
                <span className="icon is-small is-right is-hidden">
                  <i className="fa fa-exclamation-triangle"></i>
                </span>
              </div>
              <p className="help is-danger is-hidden">
                La dirección de email es inválida
              </p>
            </div>

            <div className="field">
              <label className="label">Mensaje</label>
              <div className="control">
                <textarea
                  onChange={this.onChangeTextarea}
                  className="textarea"
                  placeholder="Su mensaje aquí"
                  name="message"
                ></textarea>
              </div>
            </div>

            <div className="field is-grouped">
              <div
                className="g-recaptcha"
                data-sitekey="6LdYOb4ZAAAAABqyygx0eMM3Gm_J_6KugxG-IL_L"
              ></div>
            </div>

            <div className="field is-grouped">
              <div className="control">
                <input
                  className="button is-primary is-large"
                  type="submit"
                  value="Send"
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }

  onChangeTextarea(event) {
    this.setState({
      feedback: event.target.value,
    });
  }
  onChangeName(event) {
    this.setState({
      name: event.target.value,
    });
  }
  onChangeEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (
      this.state.name.trim() === '' ||
      this.state.email.trim() === '' ||
      this.state.feedback.trim() === ''
    ) {
      return;
    }

    const mailformat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    if (!this.state.email.match(mailformat)) {
      console.log('bad email format!');
      document.querySelector('#email').classList.add('is-danger');
      document.querySelector('p.help.is-danger').classList.remove('is-hidden');
      document
        .querySelector('span.is-small.is-right')
        .classList.remove('is-hidden');
      return;
    }
    const templateId = 'template_18dz9XOC_clone';

    this.sendFeedback(templateId, {
      message_html: this.state.feedback,
      from_name: this.state.name,
      reply_to: this.state.email,
      to_name: 'Carlos',
      from_website: 'CARLOSHDEZ.ME',
    });
  }

  sendFeedback(templateId, variables) {
    window.emailjs
      .send('gmail', templateId, variables)
      .then((res) => {
        console.log('Email successfully sent!');
        window.location.href = '/success';
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) =>
        console.error(
          'Oh well, you failed. Here some thoughts on the error that occured:',
          err
        )
      );
  }
}
